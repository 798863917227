.main-container {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    min-width: 80vw;
    max-width: auto;
    left: 12vw;

}

.appointment-container {
    z-index: 999;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    font-weight: 400;
    max-width: auto;
    min-height: auto;
    width: 25vw;
    height: auto;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow:
        2px 2px 4px rgba(0, 0, 0, 0.4),
        -2px -2px 4px rgba(255, 255, 255, 0.4);
}

.appointment-container-time-table {
    display: flex;
    flex-direction: column;
    padding: 15px;
    font-weight: 400;
    max-width: auto;
    min-height: auto;
    width: 40vw;
    height: auto;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow:
        2px 2px 4px rgba(0, 0, 0, 0.4),
        -2px -2px 4px rgba(255, 255, 255, 0.4);
}

.hours-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.appointment-hours {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    height: fit-content;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow:
        2px 2px 4px rgba(0, 0, 0, 0.4),
        -2px -2px 4px rgba(255, 255, 255, 0.4);
}

.schedule-appointment-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}


@media (max-width: 1000px) and (orientation: portrait) {
    .main-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 500px;
        width: auto;
        min-width: auto;
        max-width: auto;
        left: auto;
    }

    .appointment-container {
        z-index: 0;
        flex-direction: column;
        font-weight: 200;
        margin-right: 1vw;
        min-width: auto;
        min-height: 15vw;
        width: 90vw;
        height: fit-content;
        background-color: #ffffff;
        border-radius: 20px;
        box-shadow:
            2px 2px 4px rgba(0, 0, 0, 0.4),
            -2px -2px 4px rgba(255, 255, 255, 0.4);
    }

    .appointment-container-time-table {
        display: flex;
        flex-direction: column;
        padding: 8px;
        font-weight: 400;
        max-width: auto;
        min-height: auto;
        width: 40vw;
        height: auto;
        background-color: #ffffff;
        border-radius: 20px;
        box-shadow:
            2px 2px 4px rgba(0, 0, 0, 0.4),
            -2px -2px 4px rgba(255, 255, 255, 0.4);
    }

    .appointment-txt-btn {
        margin-top: 5px;
    }

    .appointment-container-btn {
        margin-top: 0px;
        padding: 0px;
        width: 7vw;
        height: auto;
        background-color: none;
        border-radius: 30px;
        margin-left: 10vw;
        box-shadow: none;
    }
    
    .schedule-appointment-container {
        display: flex;
        flex-direction: column;
    }
}