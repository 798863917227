.vet-description-txt-3 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row;
  justify-content: start;
  margin-top: 1rem;
  
}
.icons-secondary-services {
  width: 2rem;
}

@media (max-width: 1000px) and (orientation: portrait) {
  .vet-description-txt-3 {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: row;
    justify-content: start;
  }
}